import { createClasses } from 'styles';
import Container from '@mui/material/Container';

const useClasses = createClasses((theme) => ({
	root: {
		fontSize: '0.875rem',
		color: theme.config.palette.text.primary,
		maxWidth: theme.config.containerMaxWidth,
		[theme.breakpoints.up('lg')]: {
			marginX: 'auto',
			paddingX: theme.config.containerSpacing
		},
		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
}), {
	name: 'AppFooterContainer'
});

export default function AppFooterContainer(props) {
	const { children, ...rest } = props;
	const classes = useClasses(props);

	return (
		<Container
			maxWidth={false}
			disableGutters
			className={classes.root}
			{...rest}
		>
			{children}
		</Container>
	);
}
